import styled from "styled-components";
import React from 'react';
import { ContactInfo } from "./HeroElements";

export const EmailGrabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F5F5F5;
  padding: 70px;
  border-radius: 50px;
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
`;

export const EmailGrabText = styled.h2`
  color: #131D20;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: right;

  @media (max-width: 685px) {
    color: #007BFF; // Primary blue
  }
`;

export const EmailInput = styled.input.attrs({
  type: 'email',
  placeholder: 'ENTER YOUR EMAIL...'
})`
  padding: 10px;
  width: 300px;
  border: 2px solid #131D20;
  background-color: transparent;
  border-radius: 5px;
  margin-right: 10px;
  color: #131D20;
  font-size: 16px;
  &:focus {
    outline: none;
  }
`;

export const SignInButton = styled.button`
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #FF0000;
  color: #FF0000;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #FF0000;
    color: #FFF;
  }
`;

export const Circle = styled.div`
  width: 68px;
  height: 68px;
  background-color: #E84B3F;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 685px) {
    display: none; // Hides the circle
  }
`;

function EmailGrab() {
  return (
    <EmailGrabContainer>
      <InnerContainer>
        <Circle/>
        <div style={{ textAlign: 'right' }}>
          <EmailGrabText>LET US KNOW HOW WE CAN HELP YOU</EmailGrabText>
          <div>
          <ContactInfo>
          <a href="mailto:inquiries@vitalforcecapital.com">email: inquiries@vitalforcecapital.com</a>
          <a>X: vitalforcecap</a>
          <a href="https://www.linkedin.com/company/vitalforcelionkedin" target="_blank" rel="noopener noreferrer">
            linkedin: vitalforcelionkedin.link
          </a>
        </ContactInfo>
          </div>
        </div>
      </InnerContainer>
    </EmailGrabContainer>
  );
}

export default EmailGrab;
