export const HeroSpotlight = [
  {
    imageSrc:
      "https://vitalforceassets.s3.amazonaws.com/clean-cult-founders.jpg",
    name: "Ryan Lupberger",
    company: "Cleancult",
    description:
      "One day, during a particularly powerful spin cycle, our co-founder Ryan, noticed there were no recognizable ingredients listed on his bottle of detergent. You heard us, not one. Intrigued, he did some digging and was shocked to learn of all the artificial chemicals in conventional cleaners, natural alternatives weren’t much better either, with their toxic plastic packaging, indecipherable ingredients, and let’s face it, weak results.",
  },
  {
    imageSrc:
      "https://vitalforceassets.s3.amazonaws.com/620ae150568f3f385d0bb577_STC+Headshots.png",
    name: "Jordan Nathan",
    company: "Caraway",
    description: `We’re on a mission to craft well-designed home goods that thoughtfully raise the standards of what you cook with. Simply put, our products are designed to make your life easier and healthier.`,
  },
  {
    imageSrc:
      "https://vitalforceassets.s3.amazonaws.com/META_blog_founderama.jpg",
    name: "Simon Enver",
    company: "Quip",
    description:
      'Our journey began at a neighborhood dentist in New York, with a suggestion to use the “cheapest electric brush available” to fight the common, damaging habit of brushing too hard. Years of "innovation" had barely improved the humble toothbrush, and instead, overshadowed the far more harmful, habitual problems that still persist.',
  },
];

export const founders = [
  {
    image: "https://vitalforceassets.s3.amazonaws.com/clean-cult-founders.jpg",
    name: "Ryan Lupberger",
    company: "Cleancult",
  },
  {
    image:
      "https://vitalforceassets.s3.amazonaws.com/620ae150568f3f385d0bb577_STC+Headshots.png",
    name: "Jordan Nathan",
    company: "Caraway",
  },
  {
    image:
      "https://vitalforceassets.s3.amazonaws.com/Screen+Shot+2023-11-12+at+3.28.40+PM.png",
    name: "Simon Enver",
    company: "Quip",
  },
  {
    image: "https://vitalforceassets.s3.amazonaws.com/Zani+Gugelmann.jpeg",
    name: "Zani Gugelmann",
    company: "Llama Mama",
  },
  {
    image:
      "https://vitalforceassets.s3.amazonaws.com/AllisonandAdamGrossman.jpg",
    name: "Allison & Adam Goldman",
    company: "Seaweed Bath Co.",
  },

  {
    image:
      "https://vitalforceassets.s3.amazonaws.com/Bahiri-Mark-headshot-mu7pzg5um6auw1y7709vmksji62olb7l28ceoegjdk.jpg",
    name: "Mark Bahiri",
    company: "Emerald Creek Capital",
  },

  {
    image: "https://vitalforceassets.s3.amazonaws.com/Brian+Vengo.jpeg",
    name: "Brian Shimmerlik",
    company: "Vengo",
  },

  {
    image:
      "https://vitalforceassets.s3.amazonaws.com/Brutus+broth+founders.jpg",
    name: "Kim Hehir & Sue Delegan ",
    company: "Brutus Broth",
  },
  {
    image: "https://vitalforceassets.s3.amazonaws.com/collin.png",
    name: "Collin Eckles",
    company: "Natureza",
  },
  {
    image: "https://vitalforceassets.s3.amazonaws.com/Culture+founder.jpeg",
    name: "Thomas Moffitt",
    company: "Culture Fresh Foods",
  },
  {
    image: "https://vitalforceassets.s3.amazonaws.com/david+gale.jpg",
    name: "David Gale",
    company: "We Are the Mighty",
  },
  {
    image: "https://vitalforceassets.s3.amazonaws.com/Fernando-1.png",
    name: "Fernando Gentil",
    company: "Natureza",
  },
  {
    image: "https://vitalforceassets.s3.amazonaws.com/JaseTrace.png",
    name: "Sarah Mullins",
    company: "Buchi",
  },
  {
    image: "https://vitalforceassets.s3.amazonaws.com/Joe.png",
    name: "Joe Tonnos",
    company: "Natureza",
  },
  {
    image: "https://vitalforceassets.s3.amazonaws.com/Wink+coffee+founder.jpg",
    name: "Jayson Rapaport",
    company: "Wink Coffee",
  },
];

export const Projects = [
  {
    name: "Quip",
    image: "https://vitalforceassets.s3.amazonaws.com/vf_logos/Quip+Logo.png",
    websiteUrl: "https://www.getquip.com",
    companyType: "Consumer goods",
    founder: "Simon Enver",
    founderUrl: "https://www.linkedin.com/in/simonenever/",
    description:
      "Everyone’s got a mouth, and a healthy mouth is the gateway to a healthier you. So we strive to bring delight to your daily routine and help you guide good habits from morning refresh to bedtime brushing.",
  },
  {
    name: "Caraway",
    image:
      "https://vitalforceassets.s3.amazonaws.com/vf_logos/Caraway+Logo.png",
    websiteUrl: "https://www.carawayhome.com",
    companyType: "Manufacturing",
    founder: "Jordan Nathan",
    founderUrl: "https://www.linkedin.com/in/jordannathanprofile/",
    description:
      "We’re on a mission to craft well-designed, non-toxic ceramic cookware that thoughtfully raises the standards of what you cook with.",
  },
  {
    name: "Buchi Kambucha",
    image: "https://vitalforceassets.s3.amazonaws.com/vf_logos/Buchi+Logo.png",
    websiteUrl: "https://www.drinkbuchi.com",
    companyType: "Food & Beverage Manufacturing",
    founder: "Sarah Mullins",
    founderUrl: "https://www.linkedin.com/in/sarah-mullins-61279245/",
    description:
      "Our purpose is to craft delicious, high-quality, functional beverages, invest in the regeneration of our planet, and use business as a conduit for positive social impact.",
  },
  {
    name: "Vengo",
    image: "https://vitalforceassets.s3.amazonaws.com/vf_logos/Vengo+Logo.png",
    websiteUrl: "https://vengolabs.com/product/",
    companyType: "Media & Telecom",
    founder: "Brian Shimmerlik",
    founderUrl: "https://www.linkedin.com/in/brian-shimmerlik-19293813/",
    description:
      "See how far screens can go. Vengo enables media buyers to efficiently access target audiences at scale by connecting DOOH screens to programmatic channels through a turnkey technology and programmatic sales platform.",
  },
  {
    name: "Better Bites",
    image:
      "https://vitalforceassets.s3.amazonaws.com/vf_logos/Leahs+BB+Logo.png",
    websiteUrl: "https://betterbitesbakery.com",
    companyType: "Food & Beverage Manufacturing",
    founder: "Leah Lopez",
    founderUrl: "https://www.linkedin.com/in/simonenever/",
    description:
      "GLUTEN-FREE. DAIRY-FREE. PEANUT-FREE. TREE NUT-FREE. EGG-FREE. SOY-FREE. FISH-FREE. SHELLFISH-FREE. SESAME-FREE",
  },
  {
    name: "Brutus Broth",
    image: "https://vitalforceassets.s3.amazonaws.com/vf_logos/Brutus+Logo.png",
    websiteUrl: "https://brutusbroth.com",
    companyType: "Pet Services",
    founder: "Kimberly Hehir",
    founderUrl: "https://www.linkedin.com/in/kimberly-hehir-6753391/",
    description:
      "Brutus Broth offers a variety of all-natural food products for dogs. Fortified with turmeric, glucosamine and chondroitin, Brutus Broth offers human-grade products that are gluten, soy, yeast and dairy-free and has no artificial flavors, preservatives or colors.",
  },

  {
    name: "Clean Cult",
    image:
      "https://vitalforceassets.s3.amazonaws.com/vf_logos/Cleancut+Logo.png",
    websiteUrl: "https://www.cleancult.com",
    companyType: "Manufacturing",
    founder: "Ryan Lupberger",
    founderUrl: "https://www.linkedin.com/in/ryanlupberger/",
    description:
      "On a mission to create a cleaner kind of cult that helps people switch from a bad plastic habit to a cleaner refill ritual.",
  },

  {
    name: "Culture Fresh Foods",
    image:
      "https://vitalforceassets.s3.amazonaws.com/vf_logos/CultureFF+Logo.png",
    websiteUrl: "https://www.culturefreshfoods.com",
    companyType: "Food Production",
    founder: "Thomas Moffitt",
    founderUrl: "https://www.linkedin.com/in/thomas-moffitt-0425414/",
    description:
      "Culture Fresh Foods® is a 100% plant-based dairy alternative company based in Naugatuck, CT. We produce cultured plant-based spoonables and spreadables which include yogurts, sour cream, dips ,pudding, cream cheese, butters and ice cream mix.",
  },
  {
    name: "Emerald Creek Capital",
    image: "https://vitalforceassets.s3.amazonaws.com/vf_logos/ECC+Logo.png",
    websiteUrl: "https://emeraldcreekcapital.com",
    companyType: "Consumer goods",
    founder: "Mark Bahiri",
    founderUrl: "https://www.linkedin.com/in/emeraldcreek/",
    description:
      "With offices in Manhattan, Boston, Pennsylvania and South Florida, Emerald Creek Capital has built a reputation as the industry leader in the alternative finance space. The firm provides bridge loans secured by commercial real estate in primary markets through both its domestic and offshore investment vehicles.",
  },
  {
    name: "Hosted",
    image: "https://vitalforceassets.s3.amazonaws.com/vf_logos/Hosted+Logo.png",
    websiteUrl: "https://www.shophosted.com",
    companyType: "Consumer goods",
    founder: "Meghan Jimena",
    founderUrl: "https://www.linkedin.com/in/meghan-jimena-976a7840/",
    description:
      "Hosted is a sustainable product development company specializing in compostable disposables and renewable facial tissue. We service mass retail and grocery customers across the US and are known for our modern design, compostable packaging and are certified women-owned/operated.",
  },
  {
    name: "Llama Mama",
    image:
      "https://vitalforceassets.s3.amazonaws.com/vf_logos/LLamaMama+Logo.png",
    websiteUrl: "https://llamamamaworld.com",
    companyType: "Consumer goods",
    founder: "Zani Gugelmann",
    founderUrl: "https://www.linkedin.com/in/zani-gugelmann-3112786/",
    description:
      "Llama Mama is a delicious functional soda founded by two Latin American women. Our goal was to create a prebiotic and antioxidant tonic using Peruvian superfoods to support gut health and immunity.",
  },
  {
    name: "We Are The Mighty",
    image: "https://vitalforceassets.s3.amazonaws.com/vf_logos/WATM+Logo.png",
    websiteUrl: "https://www.wearethemighty.com",
    companyType: "Entertainment Providers",
    founder: "David Gale",
    founderUrl: "https://www.linkedin.com/in/dagale/",
    description:
      "Everyone’s got a mouth, and a healthy mouth is the gateway to a healthier you. So we strive to bring delight to your daily routine and help you guide good habits from morning refresh to bedtime brushing.",
  },
  {
    name: "Wink Coffee",
    image: "https://vitalforceassets.s3.amazonaws.com/vf_logos/Wink+Logo.png",
    websiteUrl: "https://winkcoffee.com",
    companyType: "Consumer goods",
    founder: "Jayson Rapaport",
    founderUrl: "https://www.linkedin.com/in/jayrap/",
    description:
      "Everyone’s got a mouth, and a healthy mouth is the gateway to a healthier you. So we strive to bring delight to your daily routine and help you guide good habits from morning refresh to bedtime brushing.",
  },
  {
    name: "RoemLab",
    image: "https://vitalforceassets.s3.amazonaws.com/RoemLab.png",
    websiteUrl: "https://RoemLab.com",
    companyType: "Ed Tech",
    founder: "Henri Francois & Jake Brouillard",
    founderUrl: "https://www.linkedin.com/in/henri-francois-85ba24177/",
    description: "Learn to be the best candidate for your dream job.",
  },
  {
    name: "Cleanup",
    image: "https://vitalforceassets.s3.amazonaws.com/CleanUp.png",
    websiteUrl: "https://www.cleanup.com",
    companyType: "Manufacturing",
    founder: "Matthew Wadiak",
    founderUrl: "https://www.linkedin.com/in/matthew-wadiak-9683976/",
    description:
      "We’re elevating the cleaning category and beyond with innovative, easy-to-use devices that just make it easy and satisfying to refill.  Our devices are meticulously engineered for performance and our formulas are powerful, yet safe.  Welcome to the future of cleaning..",
  },
  {
    name: "Seaweed Bath co",
    image: "https://vitalforceassets.s3.amazonaws.com/SeaWeed+bath.png",
    websiteUrl: "https://seaweedbathco.com",
    companyType: "Manufacturing",
    founder: "Adam Grossman",
    founderUrl: "https://www.linkedin.com/in/adam-grossman-189509222/",
    description:
      "We’re elevating the cleaning category and beyond with innovative, easy-to-use devices that just make it easy and satisfying to refill.  Our devices are meticulously engineered for performance and our formulas are powerful, yet safe.  Welcome to the future of cleaning..",
  },
  {
    name: "Recurrent",
    image: "https://vitalforceassets.s3.amazonaws.com/Recurrent.png",
    websiteUrl: "https://recurrent.io",
    companyType: "Online Audio and Video Media",
    founder: "Andrew Perlman",
    founderUrl: "https://www.linkedin.com/in/adam-grossman-189509222/",
    description:
      "Recurrent Ventures Inc. is an innovative digital media company that is challenging the media landscape with its proprietary approach. Its best-in-class brands like Popular Science, Domino, Outdoor Life, The Drive, Donut, Dwell, Task & Purpose and more, engage a combined audience of more than 60 million monthly visitors.",
  },
  {
    name: "No Cow",
    image: "https://vitalforceassets.s3.amazonaws.com/NoCow.png",
    websiteUrl: "https://nocow.com",
    companyType: "Manufacturing",
    founder: "Daniel Katz",
    founderUrl: "https://www.linkedin.com/in/daniel-katz-1b922587/",
    description:
      "No Cow, is a foodtech company revolutionizing the nutrition industry using alternative, plant-based proteins. No Cow delivers better taste, AND better nutrition, using plants (not cows) as our foundation, and science and technology as our guides. We are developing the first plant-based, sustainable protein alternatives that are truly superior in every “whey.”",
  },
  {
    name: "Dormify",
    image: "https://vitalforceassets.s3.amazonaws.com/Dormify.png",
    websiteUrl: "https://dormify.com",
    companyType: "E-Commerce",
    founder: "Amanda Zuckerman",
    founderUrl: "https://www.linkedin.com/in/amandapaigezuckerman/",
    description:
      "Dormify is THE destination for designing and outfitting a college dorm room, teen bedroom, or small apartment. The Company designs, manufactures, and curates a broad selection of high quality, unique and affordable on-trend décor to personalize small living spaces. The brand’s integration of styling assistance with its omnichannel marketplace has made Dormify a cult-favorite brand for a powerful, loyal Gen Z audience.",
  },
  {
    name: "CiboVita",
    image: "https://vitalforceassets.s3.amazonaws.com/Cibo+Vita.png",
    websiteUrl: "https://nocow.com",
    companyType: "Food and Beverage Services",
    founder: "Ahmet Celik",
    founderUrl: "https://www.linkedin.com/in/ahmet-celik-52247928/",
    description:
      "Cibo Vita’s products are featured on the shelves of supermarkets, warehouse clubs, major retail chains, specialty shops and c-stores across the nation. The company’s dedication to excellence has earned it numerous industry accolades, including the prestigious sofi™ and Nexty awards. In 2022, Emre Imamoglu was recognized with the Ernst & Young NJ Entrepreneur of the Year Award. ",
  },
];

export const Team = [
  {
    id: 1,
    name: "William Siegel",
    position: "Founder",
    bio: "Mr. Siegel is the Founder and Managing Director of Vital Force Capital where he leads the investment decision process. Mr. Siegel has over 30 years of investment experience and currently serves on multiple CPG boards with both branded and private label products. Mr. Siegel holds a BA in Economics from Columbia College and a J.D. from NYU School of Law.",
    imageUrl: "https://vitalforceassets.s3.amazonaws.com/IMG_7541.jpg",
  },
  {
    id: 1,
    name: "Ben Zises",
    position: "Advisor",
    bio: "Mr. Zises is an experienced entrepreneur and private equity investor. Mr. Zises actively advises several early stage companies including oral care brand quip (getquip.com), where he is a founding advisor and the first investor. Mr. Zises currently helps to lead TriArch Real Estate Group in NYC. Prior to joining TriArch, Mr. Zises helped to launch and was COO of [LJearned Media, an NYC-based content marketing and design agency where he built a real estate marketing practice. Mr. Zises holds a BA from Boston University's School of Hospitality Administration and a Certificate in Real Estate Finance & Investment from NYU.",
    imageUrl: "https://vitalforceassets.s3.amazonaws.com/ben_super.jpeg",
  },
  {
    id: 1,
    name: "Mark Zurcher",
    position: "Advisor",
    bio: `Mr. Zurcher is an experienced CPG operator and currently sits on various boards advising emerging brands. In his most recent role as CEO of Angie's Boom-Chicka-Pop, Mr. Zurcher successfully led the TPG-backed company's growth transformation which resulted in the sale to Conagra Brands. Mr. Zurcher's previous
    CEO role was leading Ann's House of Nuts through transformative growth that led to a sale to Treehouse Foods.
    Prior to running these two successful businneses, Mr. Zurcher spent over 20 years as an executive at Conagra and Kraft Foods.`,
    imageUrl:
      "https://vitalforceassets.s3.amazonaws.com/image_Mark-Zurcher_1517614515237-230x230.jpg",
  },
  {
    id: 1,
    name: "Adam Grossman",
    position: "Advisor",
    bio: `Mr. Grossman is the Co-Founder of both The Seaweed Bath Co., a naturally-derived personal care line with national distribution, and of Sea Calm Skin, a direct-to-consumer company that offers a system of products to help people manage their eczema or psoriasis. Most recently, Mr. Grossman co-founded Southern Turf Co., an artificial turf sales, marketing and installation company based in Austin. Since launching in 2019, Southern Turf Co. has grown quickly to become the largest residential turf company in the southeast. Mr. Grossman is an experienced startup founder as well as having negotiated and structured joint ventures. Mr. Grossman holds a BA in Business Economics from The University of Texas at Austin and a MS in Finance from the University of Denver.`,
    imageUrl: "https://vitalforceassets.s3.amazonaws.com/adam-grossman.jpg",
  },
  // ... more team members
];
