// ProjectsMobileView.js
import React from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import ProjectsGrid from './components/ProjectsGrid';

const ProjectsViewContainer = styled.div`
  background-color: #ffffff; // Set the background color to white
  min-height: 100vh; // Ensure it covers the full height of the viewport
  width: 100vw; // Ensure it covers the full width of the viewport
`;

export default function ProjectsMobileView() {
  return (
    <ProjectsViewContainer>
      <ProjectsGrid />
    </ProjectsViewContainer>
  );
}
