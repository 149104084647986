import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import ThemeProvider from './theme';



import ScrollToTop from './components/scroll-to-top';
import Router from './routes';


function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
      <ThemeProvider>
        <ScrollToTop/>
        <Router/>
      </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
