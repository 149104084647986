import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); // 4 columns of equal width
  grid-template-rows: auto; // rows height will be determined by the content
  gap: 20px;
  padding: 40px;
  align-items: start;
  background: #F5F5F5;
  // Adjust the grid layout for different screen sizes
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr); // 2 columns layout for medium screens
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr; // 1 column layout for small screens
  }
`;

export const GridItem = styled.div`
  position: relative; // for absolute positioning of the link inside
  background: #f8f8f8; // Adjust background color to match your design
  border: none; // No border as seen in the screenshot
  overflow: hidden; // Ensures no overflow content is visible
  &:hover {
    transform: none; // No scale on hover, to match the screenshot
  }
`;

export const ItemImage = styled.img`
  width: 100%;
  height: auto;
`;

export const ItemTitle = styled.h2`
  position: absolute; // Position the title over the image
  bottom: 20px; // Position from the bottom of the item
  left: 20px; // Padding from the left
  color: black; // Title color as white
  font-size: 15px; // Larger font size for the title
  font-weight: normal; // Bold font weight for the title
  margin: 0; // Remove any default margins
`;

export const ItemCategory = styled.span`
  position: absolute; // Position category at the top of the item
  top: 20px; // Padding from the top
  left: 20px; // Padding from the left
  color: black; // Category color as white
  font-size: 14px; // Smaller font size for the category
  font-weight: normal; // Normal font weight for the category
`;

export const ItemLink = styled(LinkR)`
  position: absolute; // Position the link at the bottom
  bottom: 20px; // Padding from the bottom
  right: 20px; // Padding from the right
  color: #fff; // Link color as white
  font-weight: bold; // Bold font weight for the link
  text-decoration: none; // No underline for the link
  background-color: rgba(0, 0, 0, 0.7); // Semi-transparent background for the link
  padding: 10px; // Padding inside the link
  border-radius: 5px; // Rounded corners for the link
  &:hover {
    text-decoration: underline; // Underline on hover for the link
  }
`;
