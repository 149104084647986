import React from 'react';
import styled from 'styled-components';
import HeroBackground from './sections/HeroBackground';
import HeroTagline from './components/HeroTagline';
import SiteLink from './components/SiteLink';
import LocationLabel from './components/LocationLabel';


export default function HeroMobile() {
  return (
    
    <>
          <HeroBackground />
          <HeroTagline />
          <SiteLink />
          <LocationLabel/>
        </>
    
  );
}
