import React from "react";
import { GridContainer, GridItem, ItemImage, ItemTitle, ItemLink, ItemCategory } from './GridElements';

const HomePageGrid = ({ Projects }) => {
  return (
    <GridContainer>
      {/* Repeat GridItem for each item in your grid */}
      <GridItem>
        <ItemImage src={Projects[0].image} alt="The Next Billion Developers" />
        <ItemCategory>INVESTMENT</ItemCategory>
        <ItemTitle>The future of toothbrush's</ItemTitle>
      </GridItem>
      <GridItem>
        <ItemImage src={Projects[5].image} alt="The Next Billion Developers" />
        <ItemCategory>INVESTMENT</ItemCategory>
        <ItemTitle>Bringing cookwear to your home</ItemTitle>
      </GridItem>
      <GridItem>
        <ItemImage src={Projects[6].image} alt="The Next Billion Developers" />
        <ItemCategory>INVESTMENT</ItemCategory>
        <ItemTitle>Keeping clean can also be green</ItemTitle>
      </GridItem>
      <GridItem>
        <ItemImage src={Projects[11].image} alt="The Next Billion Developers" />
        <ItemCategory>INVESTMENT</ItemCategory>
        <ItemTitle>Supporting Americas finest</ItemTitle>
      </GridItem>
      {/* ... other GridItems */}
      {/* Add more GridItems with similar structure for other articles or content */}
    </GridContainer>
  );
};

export default HomePageGrid;
