import { Navigate, useRoutes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import BuildersPage from './pages/BuildersPage';
import ProjectsPage from './pages/ProjectsPage';
import TeamPage from './pages/TeamPage';
import EpicsPage from './pages/EpicsPage';
import SimpleLayout from './layouts/simple/SimpleLayout';
import Page404 from './pages/Page404';
import VeidtPage from './pages/VeidtPage';

export default function Router() {
  
  const routes = useRoutes([
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: '/builders',
      element: <BuildersPage />,
    },
    {
      path: '/projects',
      element: <ProjectsPage />,
    },
    {
      path: '/team',
      element: <TeamPage />,
    },
    {
      path: '/epics',
      element: <EpicsPage />,
    },
    {
      path: '/veidt',
      element: <VeidtPage />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
