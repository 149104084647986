import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

import rootReducer from './reducers/_root.reducer'; 
import rootSaga from './sagas/_root.saga';

const sagaMiddleware = createSagaMiddleware();

const middlewareList = process.env.NODE_ENV === 'development' ?
  [sagaMiddleware, logger] :
  [sagaMiddleware];

const store = createStore(
  rootReducer,

  applyMiddleware(...middlewareList, thunk),
);

sagaMiddleware.run(rootSaga);

export default store;