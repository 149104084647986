import React from "react";
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavBarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "./NavElements";
import Logo from "../logo/Logo";

export default function NavBar({ toggle, isHomePage }) {
  return (
    <>
      <Nav isHomePage={isHomePage}>
        <NavBarContainer>
          <NavLogo to="/">
            <Logo />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars onClick={toggle} />
          </MobileIcon>
          <NavMenu>
            {/* <NavItem>
              <NavLinks
                to="/builders"
                smooth="true"
                duration="500"
                spy="true"
                exact="true"
                offset="-80"
              >
                Our Builders
              </NavLinks>
            </NavItem> */}

            <NavItem>
              <NavLinks
                to="/projects"
                smooth="true"
                duration="500"
                spy="true"
                exact="true"
                offset="-80"
              >
                Our Projects
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                to="/team"
                smooth="true"
                duration="500"
                spy="true"
                exact="true"
                offset="-80"
              >
                Our Team
              </NavLinks>
            </NavItem>
          </NavMenu>
        </NavBarContainer>
      </Nav>
    </>
  );
}
