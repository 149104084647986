import React from 'react'
import styled from 'styled-components';

const SpotlightContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 1000px; // Set the width as needed
  justify-content: flex-start; // Default alignment for left-aligned items

  &.right-aligned {
    justify-content: flex-end; // Align right-aligned items to the end
    flex-direction: row; // Keep the default row direction
  }
`;

const ImageCircle = styled.img`
  width: 170px; // Increased size
  height: 170px; // Increased size
  border-radius: 50%;
  margin: 20px;
  border: 3px solid #e84b3f; // Outer stroke
  object-fit: cover; /* Add this line */
  object-position: center; /* Add this line */
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  text-align: ${props => props.isRightAligned ? 'right' : 'left'};
`;


const Name = styled.h2`
  font-weight: 500;
  font-size: 30px;
  margin: 0;
  
`;

const CompanyName = styled.h3`
  font-weight: 300;
  margin: 10px 0;
  
`;

const Description = styled.p`
  margin: 0;
  font-size: 12px
`;



export default function BuilderSpotlight({ imageSrc, name, company, description, alignRight }) {
    return (
      <SpotlightContainer className={alignRight ? 'right-aligned' : ''}>
        {alignRight ? (
          <>
            <TextContainer isRightAligned={alignRight}>
              <Name>{name}</Name>
              <CompanyName>{company}</CompanyName>
              <Description>{description}</Description>
            </TextContainer >
            <ImageCircle src={imageSrc} alt={name} />
          </>
        ) : (
          <>
            <ImageCircle src={imageSrc} alt={name} />
            <TextContainer isRightAligned={alignRight}>
              <Name>{name}</Name>
              <CompanyName>{company}</CompanyName>
              <Description>{description}</Description>
            </TextContainer>
          </>
        )}
      </SpotlightContainer>
    );
  }
  