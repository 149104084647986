// Header.js
import React from 'react';
import styled from 'styled-components';

const H1 = styled.h1`
  color: #131d20;
  font-size: 38px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

export default function Header({ children }) {
  return <H1>{children}</H1>;
}
