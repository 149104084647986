import React, { useState, useEffect } from "react";
import NavBar from "../components/nav-bar/Nav";
import SideBar from "../components/Sidebar";
import TeamMemberSpotlight from "../components/Team/TeamSpotlight";
import { Helmet } from "react-helmet-async";
import { Team } from "../data/HeroSpotlight";
import styled from "styled-components";
import TeamMemberMobile from "../components/Team/TeamPageMobile";

// Styled H1 component
export const StyledH1 = styled.h1`
  color: #131d20; // Adjust the color as needed
  font-size: 48px; // Adjust the font size as needed
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px; // Adjust the margin as needed
`;

export const StyledMobileH1 = styled.h1`
  color: #131d20; // Adjust the color as needed
  font-size: 38px; // Adjust the font size as needed
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px; // Adjust the margin as needed
`;

export default function TeamPage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Helmet>
        <title>Our Team | Vitalforce</title>
      </Helmet>

      <SideBar $isopen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      {windowWidth > 800 ? (
        <>
      <StyledH1>Our Team</StyledH1>
      <center>
        {Team.map((spotlight, index) => (
          <TeamMemberSpotlight
            key={index}
            imageSrc={spotlight.imageUrl}
            name={spotlight.name}
            position={spotlight.position}
            company={spotlight.company}
            bio={spotlight.bio}
            alignRight={index % 2 !== 0} // Right-align every other item
          />
        ))}
      </center>
      <div style={{ height: '20px' }}></div>
      </>
      ):(
        <>
        <StyledMobileH1>Our Team</StyledMobileH1>
        <center>
        {Team.map((spotlight, index) => (
        <TeamMemberMobile
        key={index}
            imageSrc={spotlight.imageUrl}
            name={spotlight.name}
            position={spotlight.position}
            company={spotlight.company}
            bio={spotlight.bio}
            alignRight={index % 2 !== 0} // Right-align every other item
        />
        ))}
        </center>
        <div style={{ height: '20px' }}></div>
        </>
      )}
    </>
  );
}

