import React from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";

const ModalContainer = styled.div`
  position: fixed;
  top: 00px;
  left: 0;
  width: 100%;
  height: calc(100%);
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  z-index: 1000;
  overflow-y: auto; // In case the content is too long
`;

const CloseButton = styled(FaTimes)`
  font-size: 2.3rem;
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  right: 1rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CompanyType = styled.h4`
  font-size: 0.9rem;
  font-weight: normal;
  color: #333;
  margin-bottom: -1rem;
  font-weight: bold;
`;

const CompanyName = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: #000;
  margin-bottom: -0.2rem;
`;

const CompanyDescription = styled.p`
  font-size: 1rem;
  color: #000;
  margin-bottom: 2rem;
`;

const FounderTitle = styled.h3`
  font-size: 0.8rem;
  font-weight: bold;
  color: #000;
  margin-bottom: .5rem;
`;

const FounderLink = styled.a`
  font-size: 1rem;
  color: #0077cc;
  margin-bottom: 1.5rem;
  text-decoration: none;
`;

const WebsiteTitle = styled.h3`
  font-size: 0.8rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 0.5rem;
`;

const WebsiteLink = styled.a`
  font-size: 1rem;
  color: #0077cc;
  text-decoration: none;
`;

export default function ProjectModal({ project, onClose }) {
  if (!project) return null;

  return (
    <ModalContainer>
      <CloseButton onClick={onClose} />
      <ContentContainer>
        <CompanyType>{project.companyType.toUpperCase()}</CompanyType>
        <CompanyName>{project.name}</CompanyName>
        <CompanyDescription>{project.description}</CompanyDescription>
        <FounderTitle>Founders</FounderTitle>
        <FounderLink
          href={project.founderUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {project.founder}
        </FounderLink>
        <WebsiteTitle>Website</WebsiteTitle>
        <WebsiteLink
          href={project.websiteUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit
        </WebsiteLink>
      </ContentContainer>
    </ModalContainer>
  );
}
