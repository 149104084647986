import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

export const SideBarContainer = styled.div`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ $isopen }) => ($isopen ? "1" : "0")};
  transform: ${({ $isopen }) => ($isopen ? "translateY(0)" : "translateY(-100%)")};
`;

export const CloseIcon = styled(FaTimes)`
  color: #E84B3F
`

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const SideBarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px); // Decrease the size for closer spacing
  text-align: center;
  padding: 0;
  margin: 0;

  @media screen and (max-width: 480px) {
      grid-template-rows: repeat(6, 60px); // Adjust size for smaller screens
  }
`;


export const SideBarWrapper = styled.div`
  color: white;
`;

export const SideBarLink = styled(LinkR)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem; // Increased font size
  font-weight: bold; // Make it bold
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #E84B3F; // Original color
  cursor: pointer;
  padding: 10px;

  &:hover {
    color: #A0A0A0; // Space grey color on hover
    transition: 0.2s ease-in-out;
  }
`;



export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const SideBarRoute = styled(LinkR)`
  border-radius: 50px;
  background: white;
  white-space: nowrap;
  padding: 16px 64px;
  color: #E84B3F;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: blue;
    color: white;
  }
`;
