import React from 'react';
import styled from 'styled-components';
import { FaArrowRight } from 'react-icons/fa'; // Import arrow icon

const LinkContainer = styled.div`
  position: absolute;
  bottom: 5%; // Adjust as needed
  left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CompanyName = styled.span`
  color: #ffffff;
  margin-right: 10px;

  @media screen and (max-width: 510px) {
    font-size: .9rem;
  }

  @media screen and (max-width: 375px) {
    font-size: .7rem;
  }
`;

const ArrowIcon = styled(FaArrowRight)`
  color: #ffffff;
  margin-left: 5px; // Space between text and icon

  @media screen and (max-width: 510px) {
    font-size: 0.9rem; // Match text size
    margin-left: 2px;
  }

  @media screen and (max-width: 375px) {
    font-size: 0.7rem; // Match text size
    margin-left: 1px;
  }
  
  @media screen and (max-width: 320px) {
    font-size: 0.5rem; // Match text size
    margin-left: -1px;
  }
`;

export default function SiteLink({ companyName, url }) {
  return (
    <LinkContainer onClick={() => window.open('https://www.linkedin.com/company/vitalforce-capital', "_blank")}>
      <CompanyName>contact us</CompanyName>
      <ArrowIcon />
    </LinkContainer>
  );
}
