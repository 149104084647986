import React from 'react';

export default function HeroBackground() {
  const backgroundStyle = {
    backgroundImage: `url('https://vitalforceassets.s3.amazonaws.com/App+Cover+2.png')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    position: 'fixed', // Use fixed to position relative to the viewport
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1, // Ensures it stays behind other content
  };

  return <div style={backgroundStyle} />;
}
