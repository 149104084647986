import React, { useState, useEffect } from 'react'
import NavBar from '../components/nav-bar/Nav'
import SideBar from '../components/Sidebar'
import Hero from '../components/Hero'
import { Helmet } from 'react-helmet-async'
import { MdQueryBuilder } from 'react-icons/md'
import BuilderSpotlight from '../components/Builders/BuilderSpotlight'
import Builders from '../components/Builders/Builders'
import FoundersGrid from '../components/Builders/GeneralBuilders'
import { founders } from '../data/HeroSpotlight'
import FoundersMobileView from '../components/Builders/mobile/Index'

export default function BuildersPage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  console.log('this is right')
  const [isOpen, setIsOpen ] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <>
    <Helmet>
        <title>Our Builders | Vitalforce</title>
      </Helmet>

    <SideBar $isopen={isOpen} toggle={toggle}/>
    <NavBar toggle={toggle}/>
    {windowWidth > 800 ? (
      <>
    <Builders />
    <FoundersGrid founders={founders}/>
    </>
    ):(<>
    <FoundersMobileView/>
    </>)}
    </>
  )
}
