import React, { useState, useEffect } from "react";
import { Container, Content, H1 } from "./BuilderElements";
import BuilderSpotlight from "./BuilderSpotlight"; // Import this component
import { HeroSpotlight } from "../../data/HeroSpotlight";

export default function Builders() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <>
      {windowWidth > 800 ? (
        <Container>
          <Content>
            <H1>Founder Spotlight</H1>
            {HeroSpotlight.map((spotlight, index) => (
              <BuilderSpotlight
                key={index}
                imageSrc={spotlight.imageSrc}
                name={spotlight.name}
                company={spotlight.company}
                description={spotlight.description}
                alignRight={index % 2 !== 0} // Right-align every other item
              />
            ))}
          </Content>
        </Container>
     ) : (
        <Container>
          <Content>
            <H1>Mobile view coming soon</H1>
          </Content>
        </Container>
      )}
    </>
  );
}
