import React from 'react';
import styled from 'styled-components';

const SpotlightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: calc(100vw - 40px); // Adjust width for mobile view
`;

const ImageBox = styled.div`
  width: 100%;
  height: calc(100vw - 40px); // Square aspect ratio
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 10px; // Optional rounded corners
`;

const TextOverlay = styled.div`
  background: white;
  padding: 10px;
  position: absolute;
  bottom: 10px; // Positioned at the bottom of the image
  left: 10px; // Slight offset from the left
  border-radius: 5px;
`;

const Bio = styled.p`
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
  color: #131d20;
`;

const Name = styled.h2`
  font-weight: bold;
  margin: 0;
`;

const Position = styled.h3`
  font-weight: 300;
  margin: 0;
`;

export default function TeamMemberMobile({ imageSrc, name, position, bio }) {
  return (
    <SpotlightContainer>
      <ImageBox image={imageSrc}>
        <TextOverlay>
          <Name>{name}</Name>
          <Position>{position}</Position>
        </TextOverlay>
      </ImageBox>
      <Bio>{bio}</Bio>
    </SpotlightContainer>
  );
}
