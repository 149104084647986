import React from 'react';
import styled from 'styled-components';
import { founders } from "../../../data/HeroSpotlight";

const FoundersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
`;

const FounderItem = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  width: calc(100vw - 20px);
  height: calc(100vw - 20px);
  position: relative;
  margin-bottom: 30px; // Increased margin for more spacing
  display: flex;
  align-items: flex-end;
`;

const FounderInfo = styled.div`
  background: white;
  color: black;
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
`;

const FounderName = styled.p`
  margin: 0;
  font-weight: bold;
`;

const FounderCompany = styled.p`
  margin: 0;
`;

const H1 = styled.h1`
  color: #131d20;
  font-size: 38px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  color: #131d20;
  font-size: 16px;
  text-align: center;
  margin: 20px 0; // Add margin above and below the paragraph
`;

export default function FoundersMobileView() {
  return (
    <FoundersContainer>
        <H1>Our founders</H1>
        <Paragraph>
            We invest in talented people first, innovators that want to change the world.
        </Paragraph>
        {founders.map((founder, index) => (
            <FounderItem key={index} image={founder.image}>
                <FounderInfo>
                    <FounderName>{founder.name}</FounderName>
                    <FounderCompany>{founder.company}</FounderCompany>
                </FounderInfo>
            </FounderItem>
        ))}
    </FoundersContainer>
  );
}
