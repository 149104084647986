import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone'; // You'll need to install moment-timezone

const LocationContainer = styled.div`
  position: absolute;
  bottom: 5%;
  right: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 1rem;

  @media screen and (max-width: 510px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 375px) {
    font-size: 0.7rem;
  }

  @media screen and (max-width: 320px) {
    font-size: 0.5rem;
  }
`;

export default function LocationLabel() {
  const [time, setTime] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      // Get the current time in Miami
      const miamiTime = moment().tz('America/New_York').format('h:mm:ss A');
      setTime(miamiTime);
    }, 1000); // Update every second

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
    <LocationContainer>
      Miami, FL | {time}
    </LocationContainer>
  );
}
