import React from "react";
import styled from "styled-components";

// Styled component for SlideTitle
const SlideTitle = styled.h2`
  color: #ffffff;
  font-size: 3rem;
  letter-spacing: 0.01em;

  @media screen and (max-width: 768px) {
    font-size: 2.8rem;
  }

  @media screen and (max-width: 510px) {
    font-size: 2.1rem;
  }
  
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 1.7rem;
  }

  @media screen and (max-width: 320px) {
    font-size: 1.5rem;
  }
`;

const TaglineContainer = styled.div`
  position: absolute; // Position relative to the nearest positioned ancestor
  bottom: 9%; // Closer to the bottom
  left: 0;
  right: 0;
  padding: 0 20px; // Space from the sides
  text-align: left; // Center the text inside the container
`;

export default function HeroTagline({tagline}) {
  return (
    <TaglineContainer>
      <SlideTitle>
        Providing the capital that brings innovation to your world.
      </SlideTitle>
    </TaglineContainer>
  );
}

