import React, { useState, useEffect } from 'react'
import NavBar from '../components/nav-bar/Nav'
import SideBar from '../components/Sidebar'
import Hero from '../components/Hero'
import { Helmet } from 'react-helmet-async'
import { MdQueryBuilder } from 'react-icons/md'
import BuilderSpotlight from '../components/Builders/BuilderSpotlight'
import Builders from '../components/Builders/Builders'
import FoundersGrid from '../components/Builders/GeneralBuilders'
import { Projects } from '../data/HeroSpotlight'
import ProjectsGrid from '../components/Projects/Projects'
import ProjectsMobileView from '../components/Projects/Mobile/ProcetsMobile'
import { useLocation } from 'react-router-dom'

export default function ProjectsPage() {
  const location = useLocation();
  console.log(location)
  const isHomePage = location.pathname === '/home';
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  console.log('this is right')
  const [isOpen, setIsOpen ] = useState(false)
  console.log(isHomePage)
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <>
    <Helmet>
        <title>Our Projects | Vitalforce</title>
      </Helmet>

    <SideBar $isopen={isOpen} toggle={toggle}/>
    <NavBar toggle={toggle} isHomePage={isHomePage}/>

    {windowWidth > 800 ? (
    <ProjectsGrid Projects={Projects}/>
    ):(
      <ProjectsMobileView/>
    )}
    </>
  )
}