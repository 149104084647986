import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import NavBar from '../components/nav-bar/Nav'
import SideBar from '../components/Sidebar'
import Hero from '../components/Hero'
import { Projects } from '../data/HeroSpotlight'
import { Helmet } from 'react-helmet-async'

export default function HomePage() {
  console.log('this is right')
  const [isOpen, setIsOpen ] = useState(false)
  const location = useLocation();
  console.log(location)
  const isHomePage = location.pathname === '/';
  console.log(isHomePage)
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
     <Helmet>
        <title>Home | Vitalforce</title>
      </Helmet>

    <SideBar $isopen={isOpen} toggle={toggle}/>
    <NavBar toggle={toggle} isHomePage={isHomePage}/>
    <Hero Projects={Projects}/>
    </>
  )
}
