import React, { useState, useEffect } from 'react';
import {
  HeroContainer,
  HeroH1,
  HeroP,
  HeroContent,
  TextWrapper,
  HeroPLeftSpacer,
  HeroSpacer,
  HeroBg,
  HeroH1Wrapper,
  HeroH1Line,
  HeroCircle,
  ContactInfo,
  
} from './HeroElements';
import EmailGrab from './EmailGrab';
import HeroMobile from './mobile';
import HomePageGrid from './HomePageGrid';

export default function Hero({Projects}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  

  return (
    <>
      {windowWidth > 800 ? (
        <>
          <HeroContainer id="/">
            <HeroContent>
            <HeroH1>INVESTMENT</HeroH1>
            <TextWrapper>
            <HeroH1Line />
            <HeroH1>CAPITAL</HeroH1>
            </TextWrapper>
            </HeroContent>
          </HeroContainer>
          <HomePageGrid Projects={Projects}/>
          <EmailGrab/>
        </>
      ) : (
        <>
          <HeroMobile/>
        </>
      )}
    </>
  );
}
