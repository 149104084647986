import React from "react";
import {
  SideBarContainer,
  Icon,
  CloseIcon,
  SideBarLink,
  SideBarWrapper,
  SideBarMenu,
  SideBarRoute,
  SideBtnWrap,
} from "./sidebarElements";

export default function SideBar({ $isopen, toggle }) {
  return (
    <>
      <SideBarContainer $isopen={$isopen} onClick={toggle}>
        <Icon onClick={toggle}>
          <CloseIcon />
        </Icon>
        <SideBarWrapper>
          <SideBarMenu>
            <SideBarLink to="/" onClick={toggle}>
              Home
            </SideBarLink>

            {/* <SideBarLink to="/builders" onClick={toggle}>
              Our Builders
            </SideBarLink> */}

            <SideBarLink to="/projects" onClick={toggle}>
              Our Projects
            </SideBarLink>

            <SideBarLink to="/team" onClick={toggle}>
              Our Team
            </SideBarLink>
          </SideBarMenu>
        </SideBarWrapper>
      </SideBarContainer>
    </>
  );
}
