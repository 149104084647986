// ProjectsGrid.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { Projects } from '../../../../data/HeroSpotlight';
import ProjectsModal from './ProjectsModal';

const ProjectsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); // Two items per line
  grid-gap: 0; // No space between items
  padding: 20px; // Adds padding around the grid
  justify-items: center; // Center items horizontally
  background-color: #ffffff; // Ensure the container has a white background
`;


const ProjectItem = styled.a`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 90%; // Adjust the width as needed
  aspect-ratio: 1; // Keeps a square aspect ratio
  border-radius: 10px;
  overflow: hidden;
  margin: 10px; // Adds some space around each item

  &:nth-child(odd) {
    border-right: 0;
  }
`;

export default function ProjectsGrid() {
    const [selectedProject, setSelectedProject] = useState(null);
  
    const handleProjectClick = (project) => {
      setSelectedProject(project);
    };
  
    const handleCloseModal = () => {
      setSelectedProject(null);
    };
  
    return (
      <>
        <ProjectsContainer>
          {Projects.map((project, index) => (
            <ProjectItem 
              onClick={() => handleProjectClick(project)}
              key={index}
              image={project.image}
            />
          ))}
        </ProjectsContainer>
        <ProjectsModal project={selectedProject} onClose={handleCloseModal} />
      </>
    );
  }
