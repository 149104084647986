import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { MdArrowForward, MdKeyboardArrowRight } from "react-icons/md";

export const HeroContainer = styled.div`
  background: #F5F5F5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  position: relative;
  z-index: 1;
  height: 52%;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (max-width: 800px) {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 500px;
    position: relative;
    z-index: 1;

    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right 0;
      bottom: 0;
      background: linear-gradient(
          180deg, 
          rgba(0,0,0,0.2) 0%, 
          rgba(0,0,0,0.5) 100%
          ), 
          linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
      z-index: 0;
    }
  }
`;

export const HeroBg = styled.div`
  // position: absolute;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // overflow: hidden;
  // z-index: 1;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  --o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
  z-index: 1;
`;

export const HeroContent = styled.div`
  z-index: 3;
  text-align: center;
  max-width: 2000px;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 100px;
  height: 70%;

  @media screen and (max-width: 800px) {
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }
`;

export const HeroH1Wrapper = styled.div`
  display: flex;
  @media screen and (max-width: 800px) {
    background: #e84b3f;
    width: 150px; // Increased size
    height: 150px; // Increased size
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
  }
`;

export const HeroH1Line = styled.div`
  height: 30px;
  width: 152px;
  background-color: #E84B3F;
  position: absolute;
  left: 15%; // Adjust as needed to position correctly
  top: 25%;
  transform: translateY(-50%);
`;

export const HeroCircle = styled.div`
  width: 650px; /* Size of the circle */
  height: 650px; /* Size of the circle */
  border-radius: 50%; /* Make it round */
  border: 30px solid #e84b3f; /* Red border */
  position: absolute; /* Position it absolutely */
  left: 70%; /* Align to the right */
  top: 80%; /* Center it vertically */
  transform: translateY(-50%); /* Center it vertically */
  z-index: 1;
  background-image: url("https://vitalforceassets.s3.amazonaws.com/GALLIANI-COLLECTION-Miami-Downtown-First-week-Y2K-RTP.jpg"); /* Background image */
  background-size: cover; /* Cover the entire circle */
  background-position: center; /* Center the image */
`;

export const HeroH1 = styled.h1`
  color: #131d20;
  font-size: 80px;
  font-weight: 1000;
  text-align: right;
  max-width: 1200px;
  min-width: 600px;
  margin-top: -25px;

  @media screen and (max-width: 800px) {
    color: #131d20;
    font-size: 48px;
    text-align: center;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

//

export const HeroP = styled.p`
  margin-top: 14px;
  color: #131d20;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  max-width: 600px;

  @media screen and (max-width: 800px) {
    margin-top: 24px;
    color: #131d20;
    font-size: 24px;
    text-align: center;
    max-width: 600px;
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-top: -50px;
`;

export const HeroPLeftSpacer = styled.div`
  flex: 1;
  margin-left: 20px;
`;

export const HeroSpacer = styled.div`
  margin: 20px;
`;

export const ContactInfo = styled.div`
 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px; // Adjust the font size as necessary

  a {
    color: #131d20; // Link color, adjust as needed
    text-decoration: none;
    margin-bottom: 10px; // Spacing between items
    

    &:hover {
      text-decoration: underline;
    }
  }
`;
