import React from 'react';
import styled from 'styled-components';
import { founders } from '../../data/HeroSpotlight';

const FoundersGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto); // Set columns to fit content
  gap: 60px; // Keeps the existing vertical gap
  justify-content: center; // Centers the grid horizontally
  margin: 20px 0 50px; // Increased bottom margin
`;

const FounderCircle = styled.img`
  width: 150px; // Width of the circle
  height: 150px; // Height matches the width to maintain the circle shape
  border-radius: 50%; // Keeps it circular
  object-fit: cover; // Ensures the image covers the circle
  border: 2px solid #e84b3f; // Border for the image
`;

export const H1 = styled.h1`
  color: #131d20; // Adjust the color as needed
  font-size: 48px; // Adjust the font size as needed
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px; // Adjust the margin as needed
`;

export default function FoundersGrid({ founders }) {
  return (
    <>
    <H1>
        Our Founders
    </H1>
    <FoundersGridContainer>
      {founders.map((founder, index) => (
        <FounderCircle key={index} src={founder.image} alt={founder.name} />
      ))}
    </FoundersGridContainer>
    <div style={{height: '20px'}}/>
    </>
  );
}
