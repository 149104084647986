import React from 'react';
import styled from 'styled-components';
import { Projects } from '../../data/HeroSpotlight';

const ProjectsGridContainer = styled.div`
  display: grid;
  background: #F5F5F5;
  grid-template-columns: repeat(4, auto); // Set columns to fit content
  gap: 60px; // Keeps the existing vertical gap
  justify-content: center; // Centers the grid horizontally
  margin: 20px 0 50px; // Increased bottom margin
`;

export const Container = styled.div`
  background: #F5F5F5;
  margin-top: -30px;
  `;

const ProjectCircle = styled.img`
  width: 150px; // Width of the circle
  height: 150px; // Height matches the width to maintain the circle shape
  border-radius: 50%; // Keeps it circular
  object-fit: contain; // Ensures the image covers the circle
  border: 2px solid #e84b3f; // Border for the image
`;

export const H1 = styled.h1`
  padding-top: 50px;
  color: #131d20; // Adjust the color as needed
  font-size: 48px; // Adjust the font size as needed
  font-weight: bold;
  text-align: center;
  margin-bottom: 50px; // Adjust the margin as needed
`;

export default function ProjectsGrid({ Projects }) {
  return (
    <>
<Container>
      <H1>Our Projects</H1>
      <ProjectsGridContainer>
        {Projects.map((project, index) => (
          <a href={project.websiteUrl} target="_blank" rel="noopener noreferrer" key={index}>
            <ProjectCircle src={project.image} alt={project.name} />
          </a>
        ))}
      </ProjectsGridContainer>
      <div style={{ height: '20px' }}/>
      </Container>
    </>
  );
}

