import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { MdArrowForward, MdKeyboardArrowRight } from "react-icons/md";

export const Container = styled.div`
  background: #F5F5F5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 800px) {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 500px;
    position: relative;
    z-index: 1;

    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right 0;
      bottom: 0;
      background: linear-gradient(
          180deg, 
          rgba(0,0,0,0.2) 0%, 
          rgba(0,0,0,0.5) 100%
          ), 
          linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
      z-index: 0;
    }
  }
`;

export const Bg = styled.div`
  // position: absolute;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // overflow: hidden;
  // z-index: 1;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  --o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
  z-index: 1;
`;

export const Content = styled.div`
  z-index: 3;
  text-align: center;
  max-width: 2000px;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 0%;

  @media screen and (max-width: 800px) {
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }
`;

export const H1 = styled.h1`
  color: #131d20; // Adjust the color as needed
  font-size: 48px; // Adjust the font size as needed
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px; // Adjust the margin as needed
`;
